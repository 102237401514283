import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./adultsForm.css";
import { useNavigate } from "react-router-dom";
import AdultsForm from "./adultsForm";
import axios from "axios";
import jwt_decode from "jwt-decode";

function ChildCharacteristics({
  page,
  setPage,
  formData,
  setFormData,
  lockSchool,
}) {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();
  const [loadingMessage, setLoadingMessage] = useState();
  const [snackDisabled, setSnackDisabled] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const spainSchools = [
    "Col.legi Sant Pau, Tarragona",
    "Escola Arquitecte Pujol, Tarragona Els Pallaresos",
    "Escola la Parellada, Tarragona Santa Oliva",
    "Escola Puigcerver, Reus",
    "Escola General Prim, Reus",
    "Escola Martí Poch, l'Espluga de Francolí",
    "Escola Ramón Sugrañes, Almoster",
    "Escola els Baltis - Baltasar Segú, Valls",
    "Socrates Educa International School",
    "Escola Guillem Fortuny, Cambrils",
    "Escola Mas Clariana, Cambrils",
    "Escola Vedruna, Barcelona Gràcia",
    "Escola Rius i Taulet, Barcelona Gràcia",
    "Escola Reina Violant, Barcelona Gràcia",
    "Escola Orlandai, Barcelona Sarrià-Sant Gervasi",
    "Escola Els Arcs, Barcelona Sarrià-Sant Gervasi",
    "Escola Josep Guinovart, Barcelona Castelldefels",
    "Escola Thau, Barcelona Les Corts",
    "Escola Poblenou, Barcelona Sant Martí",
    "Centre d'Estudis Montseny, Barcelona Sant Martí",
    "Escola Gravi, Barcelona",
    "Escola La Sagrera, Barcelona",
    "IEA Oriol Martorell, Barcelona",
    "Escola Les Parellades, Barcelona",
    "Escola la Serra - Espai Obert, Sant Pere de Ribes",
    "Institut Escola Eixample, Barcelona l'Eixample",
    "Escola La Maquinista, Barcelona Sant Andreu",
    "Escola FEDAC, Barcelona Monistrol",
    "Escola FEDAC, Barcelona Sant Vicenç de Castellet",
    "Escola Sant Vicenç, Barcelona Sant Vicenç de Castellet",
    "Escola Torre Balldovina, Barcelona Santa Coloma de Gramenet",
    "Escola la Salle Horta, Barcelona Horta-Guinardó",
    "Escola Pins del Vallès, Sant Cugat del Vallés",
    "Escola Turó de Guiera, Cerdanyola del Vallés",
    "Agrupació Escolar Anselm Clavé, Mollet del Vallés",
    "Escola Elisa Badía, Barberà del Vallés",
    "Escola Vedruna, Vilafranca del Penedès",
    "Escola Garcia Fossas, Igualada",
    "Escola Argentona, Argentona",
    "Escola Grèvol, Barcelona Sant Martí",
    "Col.legi Claret, Barcelona Gràcia",
    "Escola Anoia, Igualada",
    "Institut Escola Turó de Roquetes, Barcelona",
    "Col.legi Xaloc, L'Hospitalet de Llobregat",
    "Escola Parc de Guinardó, Barcelona Horta-Guinardó",
    "Escola Jungfrau, Badalona",
    "Escola Fedac Amílcar, Barcelona",
    "Escola la Portalada, Altafulla",
    "Col.legi Les Muralles, Montblanc",
    "Escola Maria Cortina, Reus",
    "Escola Nausica, Barcelona Sarrià-Sant Gervasi",
    "Institució Familiar d'Educació",
  ];

  const turkeySchools = [
    // BURSA OSMANGAZİ CITY
    "SEHİT JANDARMA ER SAMET AKDENİZ PRİMARY SCHOOL",
    // "23 NİSAN SECONDARY SCHOOL",
    // BURSA KARACABEY CITY
    // "İSTİKLAL SECONDARY SCHOOL",
    // "BORSA İSTANBUL VOCATIONAL TECHNICAL AND ANATOLIAN HIGH SCHOOL",
  ];

  const moroccoSchools = [""];

  const lockSchoolOpt = [formData.school_name];

  const today = new Date();
  const currentDayOfWeek = today.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

  // Calculate the date of the Monday and Sunday
  const monday = new Date(today);
  const sunday = new Date(today);
  const nextMonday = new Date(today);
  const nextSunday = new Date(today);
  monday.setDate(today.getDate() - currentDayOfWeek + 1);
  sunday.setDate(today.getDate() - currentDayOfWeek + 7);
  nextMonday.setDate(today.getDate() - currentDayOfWeek + 8);
  nextSunday.setDate(today.getDate() - currentDayOfWeek + 14);

  // Format the Monday and Sunday date
  const m_year = monday.getFullYear();
  const m_month = String(monday.getMonth() + 1).padStart(2, "0");
  const m_day = String(monday.getDate()).padStart(2, "0");

  const s_year = sunday.getFullYear();
  const s_month = String(sunday.getMonth() + 1).padStart(2, "0");
  const s_day = String(sunday.getDate()).padStart(2, "0");

  const nm_year = nextMonday.getFullYear();
  const nm_month = String(nextMonday.getMonth() + 1).padStart(2, "0");
  const nm_day = String(nextMonday.getDate()).padStart(2, "0");

  const ns_year = nextSunday.getFullYear();
  const ns_month = String(nextSunday.getMonth() + 1).padStart(2, "0");
  const ns_day = String(nextSunday.getDate()).padStart(2, "0");

  const formattedMonday = `${m_year}-${m_month}-${m_day}`;
  const formattedSunday = `${s_year}-${s_month}-${s_day}`;
  const formattedNextMonday = `${nm_year}-${nm_month}-${nm_day}`;
  const formattedNextSunday = `${ns_year}-${ns_month}-${ns_day}`;

  useEffect(() => {
    if (
      formData.school_name !== "" &&
      formData.group_age !== "" &&
      formData.child_milk !== "" &&
      formData.child_snack !== "" &&
      formData.child_school_lunch !== ""
    ) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(user.user_id + "/profiles");
        // console.log(response.data.adults);
        // console.log(response.data.children);
        setAdults(response.data.adults);
        setChildren(response.data.children);
      } catch {}
    };
    fetchData();
  }, []);

  // const handleSchoolSelection = (school) => {
  //   setFormData({ ...formData, school_name: school });
  //   setSchoolSelection(true);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    // If a submission is already in progress, do nothing
    if (isSubmitting) return;

    const fetchData = async () => {
      if (formData.profile === "child") {
        setIsSubmitting(true); // Disable further submissions
        setLoading(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Use "smooth" for smooth scrolling behavior
        });
        try {
          const response = await axios.post(
            `${user.user_id}/createChildProfile`,
            {
              formData,
            }
          );
          if (response.status === 200) {
            if (adults.length > 0 && children.length == 0) {
              for (const adult of adults) {
                try {
                  const response2 = await axios.get(
                    `${adult.id}/${formattedMonday}/getCurrentWeekNPs`
                  );
                  if (response2.data.length === 0) {
                    navigate("/accounts");
                  } else {
                    setLoadingMessage(t("loading_message2"));
                    try {
                      const response3 = await axios.put(
                        `${adult.id}/${formattedMonday}/${formattedSunday}/updateCurrentWeekNPs`
                      );
                      if (response3.status === 200) {
                        // try {
                        //   const response4 = await axios.put(
                        //     `${adult.id}/${formattedNextMonday}/${formattedNextSunday}/updateCurrentWeekNPs`
                        //   );
                        //   setLoading(false);
                        //   navigate("/accounts");
                        // } catch (error) {
                        //   if (
                        //     error.response4.data.error ===
                        //     "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected country"
                        //   ) {
                        //     setErrorMessage(t("nps_error_1"));
                        //   }
                        //   if (
                        //     error.response4.data.error ===
                        //     "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected allergies"
                        //   ) {
                        //     setErrorMessage(t("nps_error_2"));
                        //   }
                        //   if (
                        //     error.response4.data.error ===
                        //     "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the current season"
                        //   ) {
                        //     setErrorMessage(t("nps_error_3"));
                        //   }
                        //   if (
                        //     error.response4.data.error ===
                        //     "Unfortunately, it is impossible to recommend a suitable nutritional plan due to a limited number of meals in our database for your specific profile."
                        //   ) {
                        //     setErrorMessage(t("nps_error_4"));
                        //   }
                        //   setLoading(false);
                        //   window.scrollTo({
                        //     top: 0,
                        //     behavior: "smooth", // Use "smooth" for smooth scrolling behavior
                        //   });
                        // }
                        setLoadingMessage("");
                        navigate("/accounts");
                      }
                    } catch (error) {
                      if (
                        error.response3.data.error ===
                        "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected country"
                      ) {
                        setErrorMessage(t("nps_error_1"));
                      }
                      if (
                        error.response3.data.error ===
                        "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the selected allergies"
                      ) {
                        setErrorMessage(t("nps_error_2"));
                      }
                      if (
                        error.response3.data.error ===
                        "Unfortunately, it is impossible to recommend a suitable nutritional plan because no meals found for the current season"
                      ) {
                        setErrorMessage(t("nps_error_3"));
                      }
                      if (
                        error.response3.data.error ===
                        "Unfortunately, it is impossible to recommend a suitable nutritional plan due to a limited number of meals in our database for your specific profile."
                      ) {
                        setErrorMessage(t("nps_error_4"));
                      }
                      setLoadingMessage(t("loading_message"));
                      setLoading(false);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth", // Use "smooth" for smooth scrolling behavior
                      });
                    }
                  }
                } catch {}
              }
            } else {
              navigate("/accounts");
            }
          }
        } catch {
        } finally {
          setLoading(false);
          setIsSubmitting(false);
          navigate("/accounts");
        }
      }
    };

    fetchData();
  };

  // const handleChange = (input) => {
  //   setSchoolList([]);
  //   setSchoolSelection(false);
  //   if (input === "") {
  //     setSchoolList([]);
  //   } else {
  //     regions.forEach((region) => {
  //       if (region.toLowerCase().includes(input.toLowerCase())) {
  //         setSchoolList((prevList) => [...prevList, region]);
  //       }
  //     });
  //   }
  // };

  return (
    <div>
      {loading && (
        <div className="text-center">
          <div className="spinner-border" role="status"></div>
          <div className="load-message-font">{loadingMessage}</div>
        </div>
      )}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <form onSubmit={handleSubmit}>
        <div className="ruser-profile-font">{t("school_name")}</div>
        <div className="section-display">
          {/* <label className="ruser-font">{t("school_name")}:</label> */}
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={
              lockSchool
                ? lockSchoolOpt
                : formData["country"] === "Spain"
                ? spainSchools
                : formData["country"] === "Turkey"
                ? turkeySchools
                : moroccoSchools
            }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              setFormData({ ...formData, school_name: newInputValue });
            }}
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            //   console.log(value);
            // }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Search")}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          {/* <input
            type="text"
            value={schoolSelection ? formData.school_name : searchInput}
            onChange={(event) => {
              handleChange(event.target.value);
              setSearchInput(event.target.value);
            }}
            className="upline-field"
          ></input>
          <div className="results-list">
            {!schoolSelection ? (
              schoolsList.length > 0 ? (
                schoolsList.map((school, index) => (
                  <div
                    key={index}
                    onClick={() => handleSchoolSelection(school)}
                  >
                    {school}
                  </div>
                ))
              ) : (
                <div>{searchInput.length > 0 ? t("no_schools_found") : ""}</div>
              )
            ) : null}
          </div> */}
        </div>
        {formData.country === "Spain" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) => {
                  const snackDisabled =
                    event.target.value === "12-15" ||
                    event.target.value === "16-17";
                  setFormData({
                    ...formData,
                    group_age: event.target.value,
                    child_snack: snackDisabled ? false : formData.child_snack,
                  });
                }}
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-11"}>7-11</MenuItem>
                <MenuItem value={"12-15"}>12-15</MenuItem>
                <MenuItem value={"16-17"}>16-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {formData.country === "Morocco" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) => {
                  const snackDisabled =
                    event.target.value === "12-15" ||
                    event.target.value === "16-17";
                  setFormData({
                    ...formData,
                    group_age: event.target.value,
                    child_snack: snackDisabled ? false : formData.child_snack,
                  });
                }}
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-11"}>7-11</MenuItem>
                <MenuItem value={"12-15"}>12-15</MenuItem>
                <MenuItem value={"16-17"}>16-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {formData.country === "Turkey" && (
          <>
            <div className="ruser-profile-font">{t("Group Age")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("Age")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.group_age}
                onChange={(event) => {
                  const snackDisabled = event.target.value === "12-17";
                  setFormData({
                    ...formData,
                    group_age: event.target.value,
                    child_snack: snackDisabled ? false : formData.child_snack,
                    child_milk: false,
                  });
                }}
                autoWidth
                label={t("Age")}
              >
                {/* Menu Items specific to Turkey */}
                <MenuItem value={"3-6"}>3-6</MenuItem>
                <MenuItem value={"7-9"}>7-9</MenuItem>
                <MenuItem value={"10-11"}>10-11</MenuItem>
                <MenuItem value={"12-17"}>12-17</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {formData.country === "Spain" && (
          <>
            <div className="ruser-profile-font">{t("school_lunch")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 160 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("school_lunch")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.child_school_lunch}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    child_school_lunch: event.target.value,
                  })
                }
                autoWidth
                label={t("school_lunch")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"school_lunch"}>{t("school_lunch")}</MenuItem>
                <MenuItem value={"no_school_lunch"}>
                  {t("no_school_lunch")}
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {formData.country === "Morocco" && (
          <>
            <div className="ruser-profile-font">{t("school_lunch")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 160 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("school_lunch")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.child_school_lunch}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    child_school_lunch: event.target.value,
                  })
                }
                autoWidth
                label={t("school_lunch")}
              >
                {/* Menu Items specific to Spain */}
                <MenuItem value={"school_lunch"}>{t("school_lunch")}</MenuItem>
                <MenuItem value={"no_school_lunch"}>
                  {t("no_school_lunch")}
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {formData.country === "Turkey" && (
          <>
            <div className="ruser-profile-font">{t("school_lunch")}</div>
            <FormControl sx={{ marginTop: 1, minWidth: 160 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                {t("school_lunch")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={formData.child_school_lunch}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    child_school_lunch: event.target.value,
                  })
                }
                autoWidth
                label={t("school_lunch")}
              >
                {/* Menu Items specific to Turkey */}
                <MenuItem value={"school_lunch"}>{t("school_lunch")}</MenuItem>
                <MenuItem value={"lunch_box"}>{t("lunch_box")}</MenuItem>
                <MenuItem value={"school_caffeteria"}>
                  {t("school_caffeteria")}
                </MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {/* <div className="ruser-profile-font">{"Group Age"}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "3-6"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "3-6" });
              }}
              className="user-font"
            />{" "}
            {"3-6"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "7-12"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "7-12" });
              }}
              className="user-font"
            />{" "}
            {"7-12"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "13-15"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "13-15" });
              }}
              className="user-font"
            />{" "}
            {"13-15"}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="group_age"
              value={formData.group_age}
              checked={formData.group_age === "16-18"}
              onChange={(event) => {
                setFormData({ ...formData, group_age: "16-18" });
              }}
              className="user-font"
            />{" "}
            {"16-18"}
          </label>
        </div> */}
        {/* <div className="ruser-profile-font">{t("school_lunch")}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="school_snack"
              value={formData.child_school_lunch}
              checked={formData.child_school_lunch === true}
              onChange={(event) => {
                setFormData({ ...formData, child_school_lunch: true });
              }}
              className="user-font"
            />{" "}
            {t("yes")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="school_snack"
              value={formData.child_school_lunch}
              checked={formData.child_school_lunch === false}
              onChange={(event) => {
                setFormData({ ...formData, child_school_lunch: false });
              }}
              className="user-font"
            />{" "}
            {t("no")}
          </label>
        </div> */}
        {formData.country === "Spain" && (
          <>
            <div className="ruser-profile-font">{t("milk_allergy")}</div>
            <div className="section-display">
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="milk_allergy"
                  value={formData.child_milk}
                  checked={formData.child_milk === true}
                  onChange={(event) => {
                    setFormData({ ...formData, child_milk: true });
                  }}
                  className="user-font"
                />{" "}
                {t("yes")}
              </label>
              <label style={{ display: "block" }}>
                <input
                  type="radio"
                  name="milk_allergy"
                  value={formData.child_milk}
                  checked={formData.child_milk === false}
                  onChange={(event) => {
                    setFormData({ ...formData, child_milk: false });
                  }}
                  className="user-font"
                />{" "}
                {t("no")}
              </label>
            </div>
          </>
        )}

        <div className="ruser-profile-font">{t("snack")}</div>
        <div className="section-display">
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="child_snack"
              value={formData.child_snack}
              checked={formData.child_snack === true}
              onChange={(event) => {
                setFormData({ ...formData, child_snack: true });
              }}
              disabled={
                formData.group_age === "12-15" ||
                formData.group_age === "16-17" ||
                formData.group_age === "12-17"
              }
              className="user-font"
            />{" "}
            {t("yes")}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="child_snack"
              value={formData.child_snack}
              checked={formData.child_snack === false}
              onChange={(event) => {
                setFormData({ ...formData, child_snack: false });
              }}
              disabled={
                formData.group_age === "12-15" ||
                formData.group_age === "16-17" ||
                formData.group_age === "12-17"
              }
              className="user-font"
            />{" "}
            {t("no")}
          </label>
        </div>
        <div className="nav-bar">
          <button
            className="form-button"
            onClick={() => {
              setPage(1);
            }}
            disabled={isSubmitting}
          >
            {t("Prev")}
          </button>
          <button
            className={activeButton ? "form-button" : "form-inactive-button"}
            disabled={isSubmitting}
            type="submit"
          >
            {t("Submit")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChildCharacteristics;
